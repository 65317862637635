<template>
  <div class="subpage" v-if="siteData" :key="siteData.id">
    <router-link to="/" class="subpage-backlink"><span>Home</span></router-link>
    <section
      v-for="section in siteSections"
      class="subpage-section"
      :key="section.id"
      :id="section.slug"
    >
      <h1 class="subpage-section__headline font-xl">
        {{ section.title }}
      </h1>
      <SectionContent :blocks="section.content" />
    </section>
  </div>
</template>

<script>
import SectionContent from '@/components/SectionContent';
import SmoothScroll from 'smooth-scroll';

export default {
  name: 'Subpage',
  components: {
    SectionContent,
  },
  watch: {
    loadingCount(to, from) {
      if (to == 0) {
        this.$nextTick(() => {
          this.handleHashNavigation();
        });
      }
    },
    $route: function (to, from) {
      this.handleHashNavigation();
    },
  },
  computed: {
    subpages() {
      return this.$store.state.subpages;
    },
    loadingCount() {
      return this.$store.state.loadingCount;
    },
    slug() {
      return this.$route.params.slug;
    },
    siteData() {
      return this.subpages.find((s) => {
        return s.slug == this.slug;
      });
    },
    siteSections() {
      if (this.siteData.children) return this.siteData.children;
      return [this.siteData];
    },
  },
  methods: {
    handleHashNavigation() {
      if (!this.$route.hash) return;
      if (this.loadingCount) return;
      let hash = this.$route.hash;
      if (document.querySelector(hash)) {
        this.scroll.animateScroll(document.querySelector(hash), null, {
          offset: 100,
          speed: 100,
        });
      }
    },
  },
  mounted() {
    this.scroll = new SmoothScroll();
    this.$nextTick(() => {
      this.handleHashNavigation();
    });
    this.$store.commit('resetFaviconUrl');
  },
};
</script>

<style lang="scss">
@import '@/scss/_helpers';

.subpage {
  padding: 0.75rem 0;
  box-sizing: border-box;

  @include bp-s() {
    padding: 0.5rem 0;
  }

  &-backlink {
    position: fixed;
    top: 4rem;
    right: 3rem;
    display: block;
    width: 3.5rem;
    height: 3.5rem;
    background-image: url('../assets/heart-back.svg');
    background-size: 100%;
    margin: 1rem 0 -0.5rem 0;
    z-index: 5;
    text-align: center;

    span {
      line-height: 3.2rem;
      font-size: 0.7rem;
    }
    @include bp-s() {
      display: none;
    }
  }

  &-anchornav {
    font-size: 4rem;
    font-weight: bold;
    line-height: 0.9;
    padding: 0 0.75rem;

    li {
      display: block;
      height: 4rem;
      overflow: hidden;

      @include bp-s() {
        height: 2rem;
      }
    }
    a {
      position: relative;

      &::after {
        content: '';
        display: block;
        position: absolute;
        bottom: 1.2rem;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: #000;
        opacity: 0;
      }
      &:hover {
        &::after {
          opacity: 1;
        }
      }
      @include bp-s() {
        &::after {
          bottom: 0.5rem;
        }
      }
    }

    @include bp-s() {
      display: none;
    }
  }
  &-section {
    margin: 4rem 0;

    &__headline {
      margin-bottom: 1rem;
      padding: 0 0.75rem;
    }

    @include bp-s() {
      margin: 2rem 0;
    }
  }
}
</style>